/* TournamentDetails.css */

.tournament-details-container {
  background-color: #121516;
  color: #fff;
  padding: 16px;
  border-radius: 8px;
  max-width: 400px;
  margin: 32px auto;
  font-family: "Manrope", sans-serif;
}

.tournament-header {
  text-align: center;
  margin-bottom: 16px;
  margin-top: -50px;
  margin-left: -250px;
  margin-bottom: 25px;
}

.tournament-header h1 {
  font-size: 18px;
  font-weight: 700;
  line-height: 160%;
  letter-spacing: 0.18px;
  margin-top: 12px;
  margin-left: 12px;
}

.tab-selector {
  display: flex;
  justify-content: space-around;
  background-color: #000;
  border-radius: 100px;
  padding: 4px;
  margin-top: -175px;
}

.tab {
  flex: 1;
  padding: 8px 16px;
  text-align: center;
  cursor: pointer;
  border-radius: 100px;
  color: #fff;
  font-family: Manrope;
  font-size: 12px;
  font-weight: 500;
}

.tab.active {
  background-color: #101828;
}

.tab-content {
  margin-top: 90px;
  text-align: center;
}

.tab-content h2 {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}
