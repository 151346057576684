/* src/assets/styles/onboardingPage.css */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@700&display=swap");
body {
  background-color: #121516; /* Ensuring the background matches the entire page */
  margin: 0;
  padding: 0;
}

.onboardingPage {
  padding: 24px; /* Starting content 24px from top and left */
  color: white;
}

.stepIndicators {
  display: flex;
  gap: 8px; /* 8px apart from each other */
}

.stepIndicator {
  height: 3px;
  flex: 1 0 0; /* Flex settings as specified */
  border-radius: 100px;
  background: #d0d5dd; /* Default color for non-active steps */
}

.stepIndicator.active {
  background: #00d1ff; /* Active step indicator color */
}

.stepContent {
  /* Style as needed */
}

.usernameInput {
  margin-top: 20px; /* Adjust as needed */
  padding: 10px;
  background-color: #fff;
  border: none;
  border-radius: 5px;
}

/* Add to your src/assets/styles/onboardingPage.css */
.stepInstruction {
  width: 327px;
  color: #fff;
  font-family: "Manrope", sans-serif; /* Ensure you have this font available */
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* Equals 36px */
  letter-spacing: -0.24px;
  margin-top: 25px;
  margin-left: 0px; /* 25px from the left of the screen */
}

.usernameField {
  display: flex;
  align-items: center;
  width: 344px;
  height: 65.524px;
  border-radius: 12.84px;
  background: #fff;
  margin-top: 45px;
  padding-left: 20px;
  position: relative;
}

.userIcon {
  margin-right: 10px;
}

.usernameInput {
  width: calc(100% - 36.413px);
  border: none;
  outline: none;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
  color: #666873;
  padding: 0; /* Add this line to remove default padding */
  margin: 0; /* Add this line to remove default margin */
}

/* Ensure inputs have consistent styling */
input::placeholder {
  color: #666873;
}

.nextButton {
  display: flex;
  width: 327px;
  padding: 14px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 10px;
  background: #3cfff3;
  color: #000;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  letter-spacing: 0.14px;
  position: fixed; /* This will make it stick to the bottom */
  left: 24px; /* 24px from the left */
  bottom: 24px; /* 24px from the bottom */
  cursor: pointer; /* Change cursor to pointer on hover */
  /* make it go the full width of the page upto 24px on each side */
  width: calc(100% - 48px);
}

.genderSelectionText {
  width: 327px;
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
  letter-spacing: -0.24px;
  margin-top: 25px;
  margin-left: 0px; /* Adjust if necessary to align with your design */
}

.genderSelectionSubtext {
  width: 258px;
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 19.2px */
  letter-spacing: 0.12px;
  margin-top: 12px;
  margin-left: 0px; /* Adjust if necessary */
}

.genderOptions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 70px;
}

.genderOption {
  display: flex;
  height: 133px;
  padding: 12px 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  flex: 0 0 calc(50% - 10px);
  border-radius: 14px;
  border: 2px solid var(--Primary-500, #9c28ea);
  background: #a7a7a7;
  cursor: pointer;
  text-align: center;
}

.genderOption .emoji {
  font-size: 48px;
  line-height: 1;
}

.genderOption .text {
  color: var(--Black, #161616);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.16px;
  margin-top: 12px;
}

.genderOption.selected {
  border-color: #00d1ff;
}

.genderOption:nth-child(3) {
  flex: 0 0 calc(50% - 10px);
  margin-top: 20px;
}

.countrySelectionPrompt {
  width: 327px;
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.24px;
  margin-top: 25px; /* Adjusted to 25px as requested */
  margin-left: 0px;
}

.countrySelector {
  position: relative;
  width: 344px;
  height: 65.524px;
  border-radius: 12.84px;
  background: #fff;
  margin-top: 25px; /* Adjusted to 25px to maintain consistency and follow the requested spacing */
}

.countryArrow {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}

.countrySelect {
  width: 100%;
  height: 100%;
  padding-left: 61px;
  border: none;
  border-radius: 12.84px;
  appearance: none;
  background: transparent;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
  color: #666873;
}

.countrySelect:focus {
  outline: none;
}

.tosText {
  width: 327px;
  color: #fff;
  font-family: "Manrope", sans-serif; /* Make sure Manrope is available or adjust the font-family as needed */
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* Equals 36px */
  letter-spacing: -0.24px;
  margin-top: 25px; /* 25px under the step indicators as specified */
}

.privacyText {
  width: 258px;
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 19.2px */
  letter-spacing: 0.12px;
  margin-top: 12px; /* Optional: adjust spacing as needed */
}

.checkboxContainer {
  display: flex;
  align-items: center;
  margin-top: 45px;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkboxIcon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-right: 12px;
  cursor: pointer;
}

.checkboxText {
  width: 310px;
  height: 60px;
  flex-shrink: 0;
  color: #fff;
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.12px;
}

.checkboxText a {
  color: #2795fa;
  text-decoration: none;
}

.checkboxText a:hover {
  text-decoration: underline;
}

.onboardingCountrySelectionPrompt {
  width: 327px;
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.24px;
  margin-top: 25px;
  margin-left: 0px;
}

.onboardingCountrySelector {
  position: relative;
  width: 344px;
  height: 65.524px;
  border-radius: 12.84px;
  background: #fff;
  margin-top: 25px;
}

.onboardingCountryArrow {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}

.onboardingCountrySelect {
  width: 100%;
  height: 100%;
  padding-left: 61px;
  border: none;
  border-radius: 12.84px;
  appearance: none;
  background: transparent;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
  color: #666873;
}

.onboardingCountrySelect:focus {
  outline: none;
}

.profileImageUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
}

.profilePreview {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
  border: 2px solid #3cfff3;
}

.uploadButton {
  display: inline-block;
  padding: 10px 20px;
  background-color: #3cfff3;
  color: #000;
  border-radius: 10px;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.uploadButton:hover {
  background-color: #2ee6da;
}

.usernameField {
  display: flex;
  align-items: center;
  width: calc(100% - 48px);
  height: 65.524px;
  border-radius: 12.84px;
  background: #fff;
  margin-top: 25px;
  padding: 0 20px;
  position: relative;
}

.userIcon {
  margin-right: 15px;
}

.usernameInput {
  width: 100%;
  border: none;
  outline: none;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
  color: #666873;
}

/* Update the existing stepInstruction class */
.stepInstruction {
  width: 100%;
  max-width: 327px;
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.24px;
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
}

/* Update the existing nextButton class */
.nextButton {
  width: calc(100% - 48px);
  max-width: 327px;
  padding: 14px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #3cfff3;
  color: #000;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.14px;
  position: fixed;
  left: 24px;
  bottom: 24px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;
}

.nextButton:hover {
  background-color: #2ee6da;
}

/* Add responsive design */
@media (min-width: 768px) {
  .onboardingPage {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .stepIndicators,
  .stepInstruction,
  .profileImageUpload,
  .usernameField,
  .nextButton {
    width: 100%;
    max-width: 400px;
  }
}

.loadingSpinner {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #3cfff3;
  animation: spin 1s ease-in-out infinite;
  margin-left: 10px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.uploadButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #3cfff3;
  color: #000;
  border-radius: 10px;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.uploadButton:hover {
  background-color: #2ee6da;
}

.uploadButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
