/* src/assets/styles/signupPage.css */
body {
  background-color: #121516;
  margin: 0;
  padding: 0;
}

.signupPage {
  color: white;
  padding-left: 110px;
  padding-top: 25px;
  position: relative;
  min-height: 100vh;
}

.signupLogo {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100px;
  margin-right: 50px;
}

.backButton {
  position: absolute;
  top: 25px;
  left: 35px;
  width: 45px;
  height: 45px;
  background: none;
  border: none;
  cursor: pointer;
}

.signupText {
  position: absolute;
  top: 160px;
  left: 35px;
  width: 300.317px;
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  letter-spacing: -1.14px;
}

.logoName {
  position: absolute;
  top: 180px;
  right: 0px;
  width: 15vw; /* Dynamically adjust the width based on the viewport width */
  height: auto; /* Maintain aspect ratio */
  max-width: 175px; /* Maximum size */
  min-width: 100px; /* Minimum size to ensure the logo remains visible */
}

.fullNameBox {
  position: absolute;
  top: 350px; /* Adjusted top position */
  left: 35px;
  width: 344px;
  height: 65.524px;
  flex-shrink: 0;
  border-radius: 12.84px;
  background: #fff;
  display: flex;
  align-items: center;
  padding-left: 25px;
}

.fullNameIcon {
  width: 16.413px;
  height: 18.667px;
  flex-shrink: 0;
  margin-right: 10px;
}

.fullNameIcon path {
  stroke: #666873;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.fullNameText {
  color: #666873;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
  margin-left: 20px; /* Position 20px to the right of the icon */
}

.emailBox {
  position: absolute;
  top: 435px; /* Adjusted top position */
  left: 35px;
  width: 344px;
  height: 65.524px;
  flex-shrink: 0;
  border-radius: 12.84px;
  background: #fff;
  display: flex;
  align-items: center;
  padding-left: 25px;
}

.emailIcon {
  width: 18px;
  height: 14.08px;
  flex-shrink: 0;
  margin-right: 10px; /* Adjusted margin to match icon size */
}

.emailText {
  color: #666873;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
}

.passwordBox {
  position: absolute;
  top: 520px; /* Adjusted top position */
  left: 35px;
  width: 344px;
  height: 65.524px;
  flex-shrink: 0;
  border-radius: 12.84px;
  background: #fff;
  display: flex;
  align-items: center;
  padding-left: 25px;
}

.passwordIcon {
  width: 17.177px;
  height: 19.271px;
  flex-shrink: 0;
  margin-right: 10px; /* Adjusted margin to match icon size */
}

.passwordText {
  color: #666873;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
  margin-left: 20px; /* Position 20px to the right of the icon */
}

.inputField {
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
  width: calc(100% - 50px); /* Adjust based on padding */
  color: #666873;
}

.signupSubmitButton {
  width: 344px;
  height: 50px;
  background-color: #0059c2;
  color: white;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  margin-top: 20px; /* Adjust based on layout */
  font-size: 16px;
  font-weight: bold;
}

.fullNameInput,
.emailInput,
.passwordInput {
  flex: 1;
  border: none;
  outline: none;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
  color: #666873;
  background: transparent;
  margin-left: 10px;
}

.signupButton {
  position: absolute;
  top: 610px;
  left: 35px;
  width: 344px;
  height: 65.524px;
  flex-shrink: 0;
  border-radius: 14px;
  background: #000;
  color: var(--BG, #f3f5f6);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.16px;
  border: none;
  cursor: pointer;
}

.loginPage .inputBox {
  height: 65.524px; /* Match height to ensure vertical alignment */
  display: flex;
  align-items: center; /* Center text vertically */
  padding: 0 20px; /* Adjust padding as necessary */
}

.loginPage .inputField {
  width: 100%; /* Take the full width of the parent minus padding */
  padding: 0 10px; /* Add padding to the sides */
}

.loginPage .loginButton {
  width: 344px; /* Match width to the input fields */
  height: 65.524px; /* Match height to input fields for consistency */
  border-radius: 12.84px; /* Match border-radius to input fields */
  background: #000;
  color: #f3f5f6;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.16px;
  border: none;
  cursor: pointer;
  margin-top: 20px; /* Add some space between the last input field and the button */
  display: block; /* Change from absolute positioning to block for natural flow */
  position: relative; /* Adjust position to relative */
  left: 35px; /* Align with the input boxes */
}

.loginText {
  position: absolute;
  top: 575px;
  left: 100px;
  width: 300.317px;
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
}