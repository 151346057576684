/* // src/assets/styles/profilePage.css */

.profile-page {
  background-color: #121516;
  min-height: 100vh;
  padding: 20px;
  padding-bottom: 100px; /* Add padding at the bottom for the dock */
  color: #fff;
}

.profile-picture {
  position: absolute;
  top: 60px;
  left: 25px;
  width: 104px;
  height: 104px;
  flex-shrink: 0;
  border-radius: 5px;
  overflow: hidden;
}

div.profile-picture img {
  width: 104px;
  height: 104px;
  flex-shrink: 0;
  border-radius: 5px;
  object-fit: cover;
}

.upload-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  width: 28px; /* Updated width */
  height: 28px; /* Updated height */
}

.username {
  position: absolute;
  top: 65px;
  left: 145px;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust the gap between the username and the icon */
}

.profile-settings-icon {
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.post-counter,
.follower-counter,
.following-counter {
  position: absolute;
  top: 176px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.post-counter {
  left: 25px;
}

.follower-counter {
  left: 45%;
  transform: translateX(-50%);
}

.following-counter {
  right: 25px;
}

/* Media query for devices with width similar to iPhone 14 Pro Max */
@media (min-width: 1284px) {
  .post-counter,
  .follower-counter,
  .following-counter {
    position: static;
    transform: none;
  }

  .profile-stats {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 176px;
    padding: 0 45px;
  }
}

.post-count,
.follower-count,
.following-count {
  color: #c6c6c6;
  font-family: Manrope;
  font-size: calc(1em + 1vw); /* Dynamically scale font size */
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 1px;
}

/* Media query for devices with width similar to iPhone 14 Pro Max */
@media (min-width: 1284px) {
  .post-count,
  .follower-count,
  .following-count {
    font-size: 20px; /* Fixed size for large devices */
  }
}

.post-label,
.follower-label,
.following-label {
  color: #c6c6c6;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 1px;
}

.tab-selector {
  position: absolute;
  top: 420px;
  left: 24px;
  right: 24px;
  display: flex;
  width: calc(100% - 48px);
  padding: 4px;
  align-items: flex-start;
  border-radius: 100px;
  background: #000;
}

.tab {
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 100px;
  cursor: pointer;
  color: var(--White, #fff);
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 19.2px */
  letter-spacing: 0.12px;
}

.tab.active {
  background: var(--Grey-900, #101828);
}

.tab-divider {
  position: absolute;
  top: 300px;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #ccc;
}

.photo-video-post,
.text-post {
  border-radius: 8px;
  margin-bottom: 16px; /* Adjust spacing between rows */
}

.post-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.posts-container {
  position: absolute;
  top: 3200px;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 25px;
  box-sizing: border-box;
}

.post {
  margin-bottom: 16px;
}

.photo-video-post {
  width: 155px;
  height: 204px;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
}

.text-post {
  width: 155px;
  height: 204px;
  border-radius: 8px;
  background: rgba(80, 85, 102, 0.5);
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.full-width {
  width: 326px;
}

.post-text {
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.14px;
}

.profile-settings-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #121516; /* Desired background color */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align items from the start */
  padding-top: 80px; /* 50px from the top */
  z-index: 10; /* Ensure it's above other content */
}

.close-button {
  position: absolute;
  top: 16px;
  left: 16px;
  cursor: pointer;
}

.profile-settings-title {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 262px;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--white, #fff);
  font-family: Roboto;
  font-size: 27px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.27px;
  padding-top: 20px;
}

.profile-picture-container {
  position: relative;
  display: inline-block; /* Allows the container to fit the size of the content */
}

.profile-picture-settings {
  width: 104px; /* Adjust size as needed */
  height: 104px;
  border-radius: 50%; /* Circular profile picture */
  object-fit: cover; /* Ensure the image covers the area */
}

.profile-picture-settings-container {
  position: relative;
  display: inline-block; /* Or flex, depending on your layout */
}

.profile-picture-edit {
  position: absolute;
  bottom: 0; /* Align to the bottom of the container */
  right: 0; /* Align to the right of the container */
  cursor: pointer;
  /* Adjust the size of the edit icon if necessary */
}

.loadingBar {
  position: fixed;
  top: 0;
  left: 0;
  height: 4px;
  background-color: #fff;
  display: none; /* Initially hidden */
}

.loadingBar.show {
  display: block;
  width: 0%;
  animation: loadProgress 2s linear forwards;
}

@keyframes loadProgress {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.profile-title {
  position: absolute;
  top: 220px;
  left: 16px;
  color: var(--white, #fff);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
  letter-spacing: 1px;
}

.name-label {
  position: absolute;
  top: 250px; /* 16px under "My Profile" */
  left: 16px;
  display: flex;
  width: 69px;
  height: 18px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--text, #828796);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1px;
}

.name-input {
  position: absolute;
  top: 240px; /* 250px from the top of the screen */
  left: 100px; /* 50px from the left of the page */
  width: auto; /* Adjust width as needed */
  height: 20px; /* Adjust height as needed for a single line of text */
  background-color: transparent;
  border: none; /* Border removed */
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  padding: 10px; /* Add some padding */
  resize: none; /* Prevent resizing */
}

.name-input:focus {
  outline: none; /* Removes the default focus outline */
  border: none; /* Ensures no border is added on focus */
}

.createPostTextfield {
  position: absolute;
  top: 88px;
  left: 70px;
  width: calc(100% - 50px);
  height: 150px;
  background-color: transparent;
  outline: none;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  resize: none;
}

.username-label {
  position: absolute;
  top: 282px; /* 32px under the name input */
  left: 50px;
  color: #fff;
  /* Add other styling as needed */
}

.username-input {
  position: absolute;
  top: 304px; /* Adjust based on the label's height */
  left: 50px;
  width: auto;
  height: 40px;
  background-color: transparent;
  border: none;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  padding: 10px;
  resize: none;
}

.username-input:focus {
  outline: none;
  border: none;
}

.first-in-row,
.second-in-row {
  width: 155px;
  height: 204px;
}

.full-width {
  width: 326px; /* Or 100% if it should take the full width */
  height: auto;
}

.verification-pill {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #828796;
  border-radius: 100px;
  padding: 4px 12px;
  margin-left: 8px;
  opacity: 0.25;
  position: absolute;
  top: 130px;
  left: 25px;
}

.verification-team {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.overlay-content {
  background: #1c1c1e;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  height: 600px; /* Set the height to 500px */
  margin-top: 0px; /* Adjust this value for vertical position */
  overflow-y: auto; /* Make the content scrollable */
}

.close-button {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 20px;
}

.following-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.following-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.following-info {
  flex-grow: 1;
}

.following-username {
  font-weight: bold;
  color: white;
}

.following-fullname {
  color: gray;
}

.following-button {
  background: #4d5050;
  border: none;
  border-radius: 8px;
  padding: 5px 10px;
  color: white;
  cursor: pointer;
}

.countrySelector {
  position: relative;
  width: 250px;
  height: 25px;
  border-radius: 12.84px;
  background: transparent;
  margin-top: -180px; /* Adjusted to 25px to maintain consistency and follow the requested spacing */
  margin-left: 25px;
}

.countrySelect {
  width: 100%;
  height: 25px;
  padding-left: 61px;
  border: none;
  border-radius: 16px;
  appearance: none;
  background: transparent;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
  color: #a1a4a6; /* Lighter version of #121516 */
  margin-top: 0px;
  margin-left: -50px;
}

.countrySelect:focus {
  outline: none;
}

.blocked-user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.blocked-user-username {
  color: var(--white, #fff);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 178.571% */
  letter-spacing: 0.14px;
  margin-top: -40px;
  margin-left: 50px;
  margin-bottom: -20px;
}

.unblock-button {
  background-color: #ff4d4d; /* Red background */
  color: white; /* White text */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  padding: 8px 16px; /* Padding */
  cursor: pointer; /* Pointer cursor on hover */
  font-family: Roboto; /* Font family */
  font-size: 14px; /* Font size */
  font-weight: 700; /* Font weight */
  text-transform: uppercase; /* Uppercase text */
  transition: background-color 0.3s; /* Smooth transition */
  margin-top: -100px;
  margin-left: 300px;
}

.unblock-button:hover {
  background-color: #ff1a1a; /* Darker red on hover */
}
