/* src/components/Dock.css */

.dock {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: #000;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 100;
}

/* Add styles for dock items */
.dock-item {
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}
