/* src/assets/styles/welcomePage.css */
.welcomePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  min-height: 100vh;
  background-color: #121516;
  color: white;
  padding: 30px;
  -webkit-overflow-scrolling: touch;
  /* Ensures smooth scrolling on iOS */
}

.logo {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100px;
  margin-bottom: 38px;
}

.secondLogo {
  width: 275px;
  height: 275px;
  margin-bottom: 84px;
}

.welcomeText {
  width: 194px;
  height: 51px;
  flex-shrink: 0;
  color: #fff;
  font-family: Poppins;
  font-size: 38px;
  font-style: regular;
  font-weight: 600;
  line-height: 125%;
  letter-spacing: -1.14px;
  text-align: center;
}

.login-button {
  width: 236px;
  height: 61px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #0059c2;
  color: #fff;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 61px;
  letter-spacing: 3.55px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 28px;
  -webkit-tap-highlight-color: transparent;
  /* Removes tap highlight on iOS */
}

.login-button:hover {
  background-color: #004799;
}

.signup-button {
  width: 236px;
  height: 61px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #ce2e2e;
  color: #fff;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 61px;
  letter-spacing: 3.55px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 30px;
  -webkit-tap-highlight-color: transparent;
  /* Removes tap highlight on iOS */
}

.signup-button:hover {
  background-color: #bd3434;
}

/* Overlay styles */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.overlay-content {
  background: #121516;
  padding: 2rem;
  border-radius: 12px;
  width: 90%;
  max-width: 400px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* Center children vertically */
}

.overlay-title {
  font-size: 24px;
  margin-bottom: 1rem;
}

.inputField {
  width: calc(100% - 50px);
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
  color: #666873;
  padding: 15px 0;
}

.loginPage .inputBox {
  height: 65.524px;
  /* Match height to ensure vertical alignment */
  display: flex;
  align-items: center;
  /* Center text vertically */
  padding: 0 20px;
  /* Adjust padding as necessary */
}

.loginPage .inputField {
  width: 100%;
  /* Take the full width of the parent minus padding */
  padding: 0 10px;
  /* Add padding to the sides */
}

.loginPage .loginButton {
  width: 344px;
  /* Match width to the input fields */
  height: 65.524px;
  /* Match height to input fields for consistency */
  border-radius: 12.84px;
  /* Match border-radius to input fields */
  background: #000;
  color: #f3f5f6;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.16px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  /* Add some space between the last input field and the button */
  display: block;
  /* Change from absolute positioning to block for natural flow */
  position: relative;
  /* Adjust position to relative */
  left: 35px;
  /* Align with the input boxes */
}

.loginText {
  position: absolute;
  top: 575px;
  left: 100px;
  width: 300.317px;
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
}

.loginButton {
  position: absolute;
  top: 610px;
  left: 35px;
  width: 344px;
  height: 65.524px;
  flex-shrink: 0;
  border-radius: 14px;
  background: #000;
  color: var(--BG, #f3f5f6);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.16px;
  border: none;
  cursor: pointer;
}

.signupButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 375px;
  height: 65.524px;
  flex-shrink: 0;
  border-radius: 14px;
  background: #000;
  color: var(--BG, #f3f5f6);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.16px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signupText {
  position: absolute;
  top: 160px;
  left: 35px;
  width: 300.317px;
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  letter-spacing: -1.14px;
}

.emailBox {
  position: absolute;
  top: 435px;
  /* Adjusted top position */
  left: 50%;
  transform: translateX(-50%);
  width: 344px;
  height: 65.524px;
  flex-shrink: 0;
  border-radius: 12.84px;
  background: #fff;
  display: flex;
  align-items: center;
  padding-left: 25px;
}

.emailIcon {
  width: 18px;
  height: 14.08px;
  flex-shrink: 0;
  margin-right: 10px;
  /* Adjusted margin to match icon size */
}

.emailText {
  color: #666873;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
}

.passwordBox {
  position: absolute;
  top: 520px;
  /* Adjusted top position */
  left: 50%;
  transform: translateX(-50%);
  width: 344px;
  height: 65.524px;
  flex-shrink: 0;
  border-radius: 12.84px;
  background: #fff;
  display: flex;
  align-items: center;
  padding-left: 25px;
}

.passwordIcon {
  width: 17.177px;
  height: 19.271px;
  flex-shrink: 0;
  margin-right: 10px;
  /* Adjusted margin to match icon size */
}

.passwordText {
  color: #666873;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
  margin-left: 20px;
  /* Position 20px to the right of the icon */
}