/* Styles for the HomePage */
@media only screen and (max-width: 1438px) {
  .homePageContainer {
    background-color: #121516;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center; /* This ensures horizontal centering of all children */
    padding-bottom: 50px; /* Increased from 350px to 370px to add 20px padding */
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    max-width: 100%;
  }

  .postIcon {
    position: absolute; /* Changed back from fixed to absolute */
    top: 12px;
    left: 12px;
    z-index: 1;
  }

  .createPostOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #121516;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    overflow-y: auto;
  }

  .closePostIcon {
    position: absolute;
    top: 16px;
    left: 16px;
    cursor: pointer;
    z-index: 1002;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .newPostText {
    color: var(--white, #fff);
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.24px;
    margin-top: 16px;
    margin-bottom: 20px;
  }

  .createUserProfile {
    position: absolute;
    top: 66px; /* Adjust based on the exact position of the close icon/new post text */
    left: 25px; /* Moved to 25px from the left of the screen */
    display: flex;
    align-items: center;
  }

  .createUserPfp {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .createUserName {
    color: #fff;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 19.2px */
    letter-spacing: 0.12px;
    position: relative;
    top: -7px;
  }

  .createPostTextfield {
    width: 100%;
    height: 150px;
    background-color: transparent;
    border: none;
    outline: none;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    resize: none;
    margin-bottom: 20px;
  }

  .createPostTextfield::placeholder {
    color: #a9a9a9; /* Gray color for placeholder text, ensuring visibility */
  }

  .createPostButton {
    position: fixed;
    top: 12px;
    right: 16px;
    background-color: #fff;
    color: #000;
    font-weight: bold;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    z-index: 1002;
  }

  .imagePreviewArea {
    width: 50%;
    max-height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 70px;
    top: 125px;
    overflow: hidden;
  }

  .imagePreviewArea img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .loadingBar {
    position: fixed;
    top: 0;
    left: 0;
    height: 4px;
    background-color: #fff;
    display: none; /* Initially hidden */
  }

  .loadingBar.show {
    display: block;
    width: 0%;
    animation: loadProgress 0.5s linear forwards; /* 20% faster */
  }

  @keyframes loadProgress {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }

  .postConfirmationPopup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 150px;
    background-color: rgba(18, 21, 22, 0.75); /* Semi-transparent background */
    -webkit-backdrop-filter: blur(
      10px
    ); /* Glass effect for Safari 9+, iOS 9+ */
    backdrop-filter: blur(10px); /* Glass effect */
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none; /* Initially hidden */
    z-index: 1000; /* Ensure it's above other content */
  }

  .logoDivider {
    height: 2px; /* Height of the line */
    background-color: gray; /* Color of the line */
    width: 100vw;
    left: 0;
    top: 130px; /* Adjust this value as needed */
    position: fixed; /* Fixed position at the top */
  }

  .postsDisplay {
    display: flex;
    flex-direction: column;
    align-items: center; /* This centers the children horizontally in the container */
    margin-top: 10px;
    width: 100%;
  }

  .post {
    background: rgba(
      255,
      255,
      255,
      0.08
    ); /* Slightly darker background for a less frosted effect */
    backdrop-filter: blur(
      10px
    ); /* Reduced blur for a less strong glass effect */
    -webkit-backdrop-filter: blur(10px); /* For Safari */
    border-radius: 16px;
    box-shadow: 0 6px 40px rgba(0, 0, 0, 0.3); /* Enhanced shadow for depth */
    padding: 20px;
    margin-bottom: 20px;
    transition: all 0.3s ease;
    max-width: 80%;
    min-width: 80%;
  }

  .post:hover {
    background: rgba(37, 37, 37, 0.8); /* Slightly darker on hover */
    transform: translateY(-2px);
    box-shadow: 0 6px 35px rgba(0, 0, 0, 0.2);
  }

  .post * {
    max-width: 90%; /* Prevents any child element from exceeding the .post width */
    box-sizing: border-box; /* Includes padding and border in the element's total width */
    margin-left: 0px;
  }

  .postProfileImage {
    width: 40px;
    height: 40px;
    border-radius: 20px; /* Makes it circular */
    margin-top: -10px;
    margin-left: 0px;
    object-fit: cover; /* Ensures the image does not get stretched */
  }

  .postHeader {
    width: 100%;
    display: flex;
    align-items: flex-start; /* This ensures the profile image aligns to the top */
  }

  .postUsername,
  .postContent {
    margin-left: 50px; /* Adjust this value so the postContent aligns with postUsername */
  }

  .postUsername {
    display: flex;
    width: 180.259px;
    height: 55px;
    flex-direction: column;
    justify-content: center;
    color: var(--white, #fff);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; /* 178.571% */
    letter-spacing: 0.14px;
    margin-left: 10px; /* Adjust as needed for spacing between pfp and username */
    margin-top: -25px;
  }

  .postContent {
    width: 85%; /* Ensure it takes up the full width of its parent */
    max-width: 100%; /* Prevent it from extending beyond its parent */
    color: var(--white, #fff);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 0px;
    margin-bottom: 10px;
    overflow-wrap: break-word; /* Allows words to break and wrap to the next line */
    word-wrap: break-word; /* Legacy support for word breaking */
    white-space: normal; /* Ensures whitespace is handled normally */
  }

  .likeSection {
    display: flex;
    align-items: center;
    margin-left: 45px;
  }

  .likeButton {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .likeCount {
    color: #fff;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px; /* Adjusted for 150% line-height */
    letter-spacing: -0.24px;
    margin-left: 7px;
  }

  body,
  html {
    max-width: 100vw;
    overflow-x: hidden; /* Prevents horizontal scrolling */
  }

  body {
    margin: 0;
    padding: 0; /* Ensure there's no padding that could affect the layout */
  }

  .postOptionsIcon {
    position: absolute;
    top: 30px;
    right: 10px;
    width: 20px;
    height: 30px;
    cursor: pointer;
  }

  .postDropdown {
    position: absolute;
    top: 60px;
    right: 10px;
    background-color: #424242;
    border-radius: 4px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    z-index: 1;
  }

  .postDropdownButton {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 14px;
    padding: 8px 12px;
    cursor: pointer;
    text-align: left;
  }

  .postDropdownButton:hover {
    background-color: #616161;
  }

  .notifications {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 5px;
  }

  .notifications h2 {
    margin-bottom: 10px;
  }

  .notifications ul {
    list-style-type: none;
    padding: 0;
  }

  .notifications li {
    padding: 10px;
    margin-bottom: 5px;
    border-bottom: 1px solid #ddd;
  }

  .notifications li.unread {
    background-color: #e7f3ff;
  }

  .notifications button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }

  .notifications button:hover {
    background-color: #0056b3;
  }

  .chatIcon {
    position: fixed;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 1; /* Ensure it stays below the overlay */
  }
}

/* Desktop Styles for the HomePage */
@media only screen and (min-width: 1439px) {
  /* Add your desktop styles here */
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 400px;
  background: linear-gradient(to bottom right, #6366f1, #8b5cf6);
  color: white;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  text-align: center;
  padding: 20px;
}

.popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.popup-header {
  font-size: 24px;
  font-weight: bold;
}

.popup-button {
  background: white;
  color: #6366f1;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.popup-button:hover {
  background: #f0f0f0;
}

.storySection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -20px;
}

.myStoryContainer {
  display: flex;
  justify-content: center;
  margin-top: -28px;
  margin-left: -250px;
}

.myStoryContainer .myStory {
  cursor: pointer;
  text-align: center;
  margin-right: 10px;
}

.myStoryContainer .myStory img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.myStoryContainer .myStory p {
  margin-top: 5px;
  font-size: 12px;
  color: #fff;
}

.plusButton {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 24px;
  height: 24px;
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
}

.storyViewer {
  width: 100%;
  margin-top: 10px;
}

.storyViewer p {
  color: #888;
}

.storiesPreview {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: -155px;
  margin-left: 200px;
}

.storyPreview {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
  margin-top: 15px;
}

.hasStory .storyProfileImageContainer {
  position: relative;
}

.hasStory .storyProfileImageContainer::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 3px solid #1da1f2;
}

.storyProfileImage {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.storyProfileImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes storyOpen {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  25% {
    transform: scale(0.5);
    opacity: 0.5;
  }
  50% {
    transform: scale(0.75);
    opacity: 0.75;
  }
  75% {
    transform: scale(0.9);
    opacity: 0.9;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.storyOverlay {
  animation: storyOpen 0.15s ease-out forwards;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 0;
}

.storyOverlayContent {
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.storyOverlayContent * {
  pointer-events: auto;
}

.dropdownMenu {
  pointer-events: auto;
}

.storiesContainer {
  width: 100%;
  height: 100%;
}

.story-subheading {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  margin-top: 5px;
}

.storyPreview .storyProfileImageContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 86px;
  height: 86px;
  overflow: visible;
  padding: 3px; /* Ensure the ring is not cut off */
  margin: 0px; /* Compensate for the padding to avoid moving elements */
  box-sizing: content-box; /* Ensure padding is added outside the border */
}

.storyPreview.notViewed .storyProfileImageContainer::before,
.storyPreview.viewed .storyProfileImageContainer::before {
  content: "";
  position: absolute;
  top: -3;
  left: -3;
  width: 86px;
  height: 86px;
  border-radius: 50%;
}

.storyPreview.notViewed .storyProfileImageContainer::before {
  border: 3px solid #1da1f2; /* Blue ring */
}

.storyPreview.viewed .storyProfileImageContainer::before {
  border: 3px solid gray; /* Gray ring */
}

.storyProfileImage {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.threeDotMenu {
  position: relative;
  z-index: 1000;
}

.dropdownMenu {
  position: absolute;
  top: 20px;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdownMenu button {
  background: none;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.dropdownMenu button:hover {
  background-color: #f0f0f0;
}

.prevStoryButton {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1001;
}

.storyHeader {
  position: absolute;
  top: 20px;
  left: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 1001; /* Ensure the header is above the story content */
}

.storyHeader .storyProfileImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.storyHeading {
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.adPost {
  /* No need for additional styles as it will inherit from .post */
}

.adPost .postUsername {
  color: #888;
  font-style: italic;
}

.adPost .postContent {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.adPost .adsbygoogle {
  display: block;
  width: 100%;
  height: 100%;
}

.storyPreview.viewed .storyProfileImageContainer::before {
  border: 3px solid #808080; /* Gray ring for viewed stories */
}

.storyHeader.viewed .storyProfileImage {
  opacity: 0.7; /* Slightly dim the profile image for viewed stories */
}

.storyHeader.viewed .storyHeading {
  color: #808080; /* Gray text for viewed stories */
}
