.backButton {
  color: rgb(255, 255, 255); /* Change color to white */
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
  width: 36px; /* Adjust width here */
  height: 36px; /* Adjust height here */
  fill: currentColor; /* Ensures the SVG path uses the color property */
}

.postTitle {
  color: var(--white, #fff);
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 178.571% */
  letter-spacing: 0.18px;
  position: absolute;
  top: 25px;
  left: 75px;
}
